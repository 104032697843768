import {Map} from 'immutable'
import PropTypes from 'prop-types'

import {BoxGutterMedium} from '../../blocks/Boxes'
import routerUtils from '../../../../../lib/routerUtils'
import {corpMaxEverplanPreviewPath} from '../../../../../lib/urlTools'

import Logger from '../../../../../lib/NewLogger'

import './acceptedDeputyFor.scss'


const AcceptedDeputyFor = props => {
  const onEverplanPreviewClick = () => {
    const everplanId = props.deputyOwnership.get('everplan-id')

    Logger.log({
      name: 'clicked_everplan_preview',
      payload: {
        actor: 'deputy',
        context: 'deputy_dashboard',
        everplan_id: everplanId
      }
    })

    return routerUtils.push(`${corpMaxEverplanPreviewPath(everplanId)}?context=deputy_dashboard`)
  }

  return (
    <BoxGutterMedium className='accepted-deputy-for'>
      <div className='everplan-owner-profile'>
        <div>
          <h3 className='name'>{props.deputyOwnership.get('everplan-name')}</h3>
          {
            props.deputyOwnership.getIn(['everplan-owner', 'owners']).map(owner => (
              <p
                key={owner.get('id')}
                className='email'>
                {owner.get('email')}
              </p>
            ))
          }
        </div>
        <a onClick={onEverplanPreviewClick} className='action-link'>View Plan</a>
      </div>
    </BoxGutterMedium>
  )
}

AcceptedDeputyFor.propTypes = {
  deputyOwnership: PropTypes.instanceOf(Map)
}

export default AcceptedDeputyFor
