import {List, Map} from 'immutable'
import PropTypes from 'prop-types'

import FormFieldByPrimitive from '../../../lib/FormFieldByPrimitive'
import {elementFieldsViewData} from '../../../../../lib/viewTools'

const ElementFields = props => {
  let fieldData = props.element.merge(elementFieldsViewData({view: props.view, views: props.views, elementId: props.element.get('id')}))

  // TODO: Update to use the `mappings` on the element when all components using this use Transformer
  const listMapping = props.listMappings.get(props.element.get('list-mapping'))

  if (listMapping && listMapping.size)
    fieldData = fieldData.set('items', listMapping.get('mappings').map(mapping => mapping.set('label', mapping.get('value'))))

  if (props.conditionalValue)
    fieldData = fieldData.set('value', props.conditionalValue)

  return (
    <div>
      <AllPrimitiveFormFields {...props} fieldData={fieldData} />
      {props.showAddAnotherButton && <a className='add-another' onClick={props.addAnotherElementField}>Add Another</a>}
    </div>
  )
}

// Putting AllPrimitiveFormFields in this component is reasonable because it
// should not be used reasonably outside this component and there is no much to
// test without the component above (ElementFields)
const AllPrimitiveFormFields = props => (
  <div>
    {
      props.fields.toIndexedSeq().map(field => {
        const fieldData = props.fieldData.set('id', field)
        const previousResponse = props.data.find(datum => datum.get('id') === field)
        return (
          <FormFieldByPrimitive
            {...props}
            data={previousResponse ? fieldData.merge(previousResponse) : fieldData}
            key={field}
          />
        )
      })
    }
  </div>
)

const sharedPropTypes = {
  data: PropTypes.instanceOf(List),
  element: PropTypes.instanceOf(Map),
  fields: PropTypes.instanceOf(List),
  newContacts: PropTypes.instanceOf(List),
  updateFileUploadStatus: PropTypes.func
}

AllPrimitiveFormFields.propTypes = {
  ...sharedPropTypes,
  fieldData: PropTypes.instanceOf(Map),
  eventType: PropTypes.string
}

ElementFields.propTypes = {
  ...sharedPropTypes,
  addAnotherElementField: PropTypes.func,
  showAddAnotherButton: PropTypes.bool,
  views: PropTypes.instanceOf(List)
}


export default ElementFields
