import PropTypes from 'prop-types'
import {Map} from 'immutable'

import './advisorDetailsSubContent.scss'

const AdvisorDetailsSubContent = ({advisorContactCard}) => {
  const title = advisorContactCard.get('title')
  return (
    <div className='advisor-details-sub-content flex-child flex-container'>
      <h5 className='contact-row flex-child'>{advisorContactCard.get('name')}</h5>
      {title && <p className='contact-row flex-child'>{title}</p>}
      <p className='contact-row flex-child'>{advisorContactCard.get('firm-name')}</p>
    </div>
  )
}


AdvisorDetailsSubContent.propTypes = {
  advisorContactCard: PropTypes.instanceOf(Map)
}

export default AdvisorDetailsSubContent
