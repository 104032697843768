import {Component} from 'react'
import {Map} from 'immutable'
import PropTypes from 'prop-types'

import {BoxGutterLarge} from '../../blocks/Boxes'
import {Button} from '../../blocks/Buttons'
import {TextGutterMedium} from '../../blocks/Texts'

import routerUtils from '../../../../../lib/routerUtils'

export default class TfaInformation extends Component {
  constructor() {
    super()

    this.onClick = this.onClick.bind(this)
  }

  onClick() {
    const baseUrlPath = this.props.userConfig.get('everplan-id') ? 'cm' : 'account'
    routerUtils.push(`${baseUrlPath}/tfa-setting`)
  }

  bodyText() {
    if (this.props.userConfig.get('tfa-enabled'))
      return 'Two-Step verification is enabled for your account. Manage your settings here.'
    else
      return 'Two-Step verification is not enabled for your account. We recommend that you turn on this extra layer of security as soon as possible.'
  }

  render() {
    return (
      <BoxGutterLarge>
        <h2>Two-Step Verification:</h2>
        <TextGutterMedium>{this.bodyText()}</TextGutterMedium>
        <Button onClick={this.onClick}>Manage</Button>
      </BoxGutterLarge>
    )
  }
}

TfaInformation.propTypes = {userConfig: PropTypes.instanceOf(Map)}

