/** @function userConfigWithUserWrapper
 *
 * @desc
 * Higher-order function that automatically loads the user-config and makes sure that a loader
 * is shown until the user-config is loaded.
 *
 * @param {ChildComponent} Component - The React component that needs access to `user-config`
 *  that will be treated as the children of the newly-created container class.
 *
 */

import {Component} from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {readEndpoint} from 'redux-json-api'

import Loader from './NewLoader'

// TODO: Rename this to something that includes getting plans as well
const userConfigWithUserWrapper = ChildComponent => {
  class UserConfigWithUserWrapper extends Component {
    componentDidMount() {
      this.props.readEndpoint('user-configs').then(response => {
        this.props.readEndpoint(`users/${response.body.data.id}?include=profile,partner,assessment`)
      })

      // This needs to be done here, instead of in the `MetaDataContainer` because it requires that a user be authenticated
      // and other then the JICL routes all other routes are wrapped with this one.
      this.props.readEndpoint('plans').then(response => this.props.corpMaxActions.reshapePlans(response.body))
    }

    render() {
      const {loadingUserInfo, ...otherProps} = this.props

      if (loadingUserInfo) return <Loader loading={true} />
      else return <ChildComponent {...otherProps} />
    }
  }

  UserConfigWithUserWrapper.propTypes = {
    corpMaxActions: PropTypes.shape({
      reshapePlans: PropTypes.func
    }),
    readEndpoint: PropTypes.func,
    loadingUserInfo: PropTypes.bool

  }

  const mapStateToProps = state => ({
    loadingUserInfo: !state.api['user-configs'] || !state.api.users || state.plans.isEmpty()
  })

  return connect(mapStateToProps, {readEndpoint})(UserConfigWithUserWrapper)
}

export default userConfigWithUserWrapper
