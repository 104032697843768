import PropTypes from 'prop-types'
import {Map} from 'immutable'

import {addPossessive} from '../../../../../lib/tools'
import {BoxGutterMedium} from '../../blocks/Boxes'
import {Icon} from '../../../../../shared_components/Icon'
import {TextGutterMedium} from '../../blocks/Texts'

import './providedByInformation.scss'


const ProvidedByInformation = ({advisorContactCard}) => (
  <BoxGutterMedium className='provided-by-information'>
    <h2>Your Everplan Is Provided By:</h2>
    <div className='provided-by-information-contact-card flex-container'>
      {
        advisorContactCard.get('avatar-url') &&
        <img alt={`${addPossessive(advisorContactCard.get('name'))} head shot`} className='advisor-head-shot flex-child' src={advisorContactCard.get('avatar-url')} />
      }
      <div className='contact-information flex-child flex-container'>
        <div className='contact-information-row flex-child'>
          <strong>{advisorContactCard.get('name')}</strong>
        </div>
        <div className='contact-information-row flex-child'>{advisorContactCard.get('firm-name')}</div>
        <div className='contact-information-row flex-child'>
          <Icon className='fa-envelope-o' />
          <a href={`mailto:${advisorContactCard.get('email')} `}>Send an email</a>
        </div>
        {
          advisorContactCard.get('phone') &&
          (
            <div className='contact-information-row flex-child'>
              <Icon className='fa-phone' />
              {advisorContactCard.get('phone')}
            </div>
          )
        }
        {
          advisorContactCard.get('url') &&
          (
            <div className='contact-information-row flex-child'>
              <Icon className='fa-globe' />
              <a href={advisorContactCard.get('url')} target='_blank' rel='noreferrer'>Visit my website</a>
            </div>
          )
        }
      </div>
    </div>
    <TextGutterMedium>If you would like to disassociate your account, please contact {advisorContactCard.get('name')}.</TextGutterMedium>
  </BoxGutterMedium>
)

ProvidedByInformation.propTypes = {advisorContactCard: PropTypes.instanceOf(Map)}

export default ProvidedByInformation
