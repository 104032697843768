import {useEffect} from 'react'
import PropTypes from 'prop-types'

import {Button} from '../dashboard/src/components/blocks/Buttons'
import clipboardIcon from '../shared_assets/clipboard.svg'
import meetingIcon from '../shared_assets/meeting.svg'
import rocketIcon from '../shared_assets/rocket.svg'
import routerUtils from '../lib/routerUtils'
import {accountSettingsUrl, goToPaymentForm} from '../lib/urlTools'
import Closer from './core/closer/Closer'
import Modals from './core/modals/Modals'
import Logger from '../lib/NewLogger'
import MixpanelLogger from '../lib/MixpanelLogger'

import './premiumAccessDenied.scss'


const PremiumAccessDenied = props => {
  const query = props.location.query
  const prevPath = props.prevPath || query.prevPath
  const itemSlug = props.itemSlug || query.itemSlug

  useEffect(() => {
    Logger.log({name: 'viewed_premium_modal'})
  }, [])

  const goToPreviousOrAccountSettingsRoute = () => {
    if (prevPath)
      return routerUtils.push(prevPath)

    return routerUtils.setLocation(accountSettingsUrl)
  }

  const modalCloser = () => (
    <Closer closer={goToPreviousOrAccountSettingsRoute} />
  )

  const onClick = () => {
    let new_query = `?prevPath=${prevPath || 'cm/dashboard'}`

    if (itemSlug) new_query += `&itemSlug=${itemSlug}`

    MixpanelLogger.track('click_get_premium')
    return goToPaymentForm(new_query)
  }

  return (
    <Modals.LinkPopUpModalLarge closerComponent={modalCloser} showModalOnMount={true} className='premium-access-denied'>
      <div>
        <h2 className='heading'>Unlock it all with Premium</h2>
        <p className='body-text'>Get everything in order with Premium, which is helpful for organizing important documents and getting your legacy plan in order.</p>
        <div className='features'>
          <div className='feature-item'>
            <div className='circle-container'>
              <img className='unlimited-items' src={rocketIcon} alt='Unlimited items' />
            </div>
            <p>Add an unlimited number of items to your Everplan</p>
          </div>
          <div className='feature-item'>
            <div className='circle-container'>
              <img className='guidance' src={meetingIcon} alt='Guidance' />
            </div>
            <p>Gain additional guidance with step-by-step interactive tools</p>
          </div>
          <div className='feature-item'>
            <div className='circle-container'>
              <img className='checklist' src={clipboardIcon} alt='Checklist' />
            </div>
            <p>Downloadable checklists for various situations</p>
          </div>
        </div>
        <p className='final-note'>Get more out of Everplans with Premium</p>
        <Button onClick={onClick}>Upgrade now</Button>
      </div>
    </Modals.LinkPopUpModalLarge>
  )
}

PremiumAccessDenied.propTypes = {
  location: PropTypes.shape({
    query: PropTypes.shape({
      prevPath: PropTypes.string,
      itemSlug: PropTypes.string
    })
  }),
  prevPath: PropTypes.string,
  itemSlug: PropTypes.string
}

PremiumAccessDenied.defaultProps = {
  location: {
    query: {}
  }
}

export default PremiumAccessDenied
