import AdvisorDetailsSubContent from './AdvisorDetailsSubContent'
import PropTypes from 'prop-types'
import {Map} from 'immutable'

import './advisorDetails.scss'

const AdvisorDetails = ({advisorContactCard}) => (
  <div className='advisor-details flex-child flex-container'>
    <img alt='Advisor Avatar' className='flex-child' src={advisorContactCard.get('avatar-url')} />
    <AdvisorDetailsSubContent advisorContactCard={advisorContactCard} />
  </div>
)

AdvisorDetails.propTypes = {
  advisorContactCard: PropTypes.instanceOf(Map)
}

export default AdvisorDetails
