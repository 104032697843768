import PropTypes from 'prop-types'
import {useFormContext, Controller} from 'react-hook-form'
import {MenuItem} from '@mui/material'

import TextInput from './TextInput'

const Select = ({defaultValue, required, errorMessage, name, label, options, placeholder}) => {
  const {control, formState: {errors}} = useFormContext()

  return (
    <Controller
      name={`${name}.value`}
      control={control}
      defaultValue={defaultValue}
      rules={required ? {required: errorMessage} : {}}
      render={({field}) => (
        <TextInput
          {...field}
          select
          label={label}
          error={!!errors[name]?.value}
          helperText={errors[name]?.value?.message}
          placeholder={placeholder}>
          {
            options.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))
          }
        </TextInput>
      )}
    />
  )
}

Select.defaultProps = {
  defaultValue: ''
}

Select.propTypes = {
  defaultValue: PropTypes.string,
  errorMessage: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  placeholder: PropTypes.string,
  required: PropTypes.bool
}

export default Select
