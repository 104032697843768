/** @class DeleteContact
 *
 * @desc
 * Component used to display an icon that, when clicked, opens a confirmation modal
 * from which either a given contact can be deleted or the user is informed about
 * why a contact cannot be deleted.
 *
 * @todo Fix up styling once final mocks are in place.
 * @todo Wire up logging of various types.
 */

import {useState} from 'react'
import {List} from 'immutable'
import PropTypes from 'prop-types'

import BasicModal from '../../../../../shared_components/mui_base_components/modals/basic_modal/BasicModal'
import DeleteIcon from '../../../../../shared_assets/v2/DeleteIcon'
import DeleteContactConfirmation from './DeleteContactConfirmation'
import UndeletableContactModalContents from './UndeletableContactModalContents'

import './deleteContact.scss'

// Colors from the style guide:
const headerTextColor = '#262626'
const inputDisabledPlaceholderText = '#A8A8A8'


const DeleteContact = props => {
  const [showModal, setShowModal] = useState(false)

  const hideModal = () => { setShowModal(false) }

  return (
    <div className='delete-contact flex-child flex-container'>
      <a data-testid='modal-trigger' onClick={() => setShowModal(true)}>
        <DeleteIcon
          alt={`Remove ${props.contactLabel} as your contact`}
          fill={props.contactItems.isEmpty() ? headerTextColor : inputDisabledPlaceholderText}
          height='15'
          width='15'
        />
      </a>
      {
        showModal && props.contactItems.isEmpty() ?
          <DeleteContactConfirmation {...props} hideModal={hideModal} /> :
          <BasicModal
            onClose={hideModal}
            open={showModal}>
            <UndeletableContactModalContents
              contactLabel={props.contactLabel}
              contactItems={props.contactItems}
            />
          </BasicModal>
      }
    </div>
  )
}

DeleteContact.propTypes = {
  contactId: PropTypes.string,
  contactItems: PropTypes.instanceOf(List),
  contactLabel: PropTypes.string
}

export default DeleteContact
