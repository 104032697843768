import {useState} from 'react'
import PropTypes from 'prop-types'

import Button from '../../../../../shared_components/Button'
import SettingsActions from '../../../actions/SettingsActions'

const DisableWarning = props => {
  const [loading, setLoading] = useState(false)

  const cancel = () => { props.closer() }

  const disable = () => {
    SettingsActions.disableTfa()
    setLoading(true)
  }

  return (
    <div>
      <div className='wizard-body'>
        <h3>Are you sure?</h3>
        <hr />
        <p>Are you sure that you want to disable two-step verification for this account?</p>
      </div>

      <Button className='wizard-secondary-btn' onClick={cancel}>Cancel</Button>
      <Button className='wizard-primary-btn' onClick={disable} loading={loading}>Disable two-step verification</Button>
    </div>
  )
}

DisableWarning.propTypes = {closer: PropTypes.func}

export default DisableWarning
