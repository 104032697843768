import classnames from 'classnames'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import React, {Fragment} from 'react'
import {readEndpoint} from 'redux-json-api'
import moment from 'moment'
import {List, Map} from 'immutable'

import * as Buttons from '../../../../dashboard/src/components/blocks/Buttons'
import {TextGutterMedium} from '../../../../dashboard/src/components/blocks/Texts'
import {CouponApplied, PaymentListText} from '../../../../lib/subscriptionTextTools'
import CouponCode from './CouponCode'
import CreditCardFormController from './CreditCardFormController'
import Forms from '../../../../shared_components/forms'
import GlobalStyleOverrides from '../../../../shared_components/core/style-overrides/GlobalStyleOverrides'
import SecureImage from '../../assets/images/secure.png'

import {accountSettingsUrl, corpMaxDashboardUrl} from '../../../../lib/urlTools'
import Container from '../../../../lib/Container'
import {cleanResponse} from '../../../../dashboard/src/components/corp_max/redux/apiHelper'
import {findResourceByAttributeId} from '../../../../lib/plan_data/userData'
import Logger from '../../../../lib/NewLogger'
import {goToInterstitials} from '../../../../lib/interstitialTools'
import {sendCardToStripe} from '../../../../lib/stripeUtils'
import SessionApi from '../../../../auth/src/web/SessionApi'
import SessionStore from '../../../../auth/src/stores/SessionStore'
import storePrototype from '../../../../shared_components/StorePrototype'
import storeWrapper from '../../../../shared_components/storeWrapper'
import SubscriptionActions from '../../actions/SubscriptionActions'

import './paymentPage.scss'

Container.registerStore('coupon', storePrototype(SubscriptionActions.Types.DID_APPLY_COUPON))
Container.registerStore('subscription', storePrototype(SubscriptionActions.Types.DID_CREATE_SUBSCRIPTION))


export class PaymentPage extends React.Component {
  constructor() {
    super()

    this.state = {errors: [], coupon: {}, processingCoupon: false, processing: false, sendUserToInterstitials: false}
    this.store = Container.getStore('coupon')
    this.subscriptionStore = Container.getStore('subscription')

    this.onSubscribed = this.onSubscribed.bind(this)
    this.thankYouModalPath = this.thankYouModalPath.bind(this)
    this.onUpdate = this.onUpdate.bind(this)
    this.stripeResponseHandler = this.stripeResponseHandler.bind(this)
    this.applyCoupon = this.applyCoupon.bind(this)
    this.clearCoupon = this.clearCoupon.bind(this)
    this.createSubscription = this.createSubscription.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.goToNextPage = this.goToNextPage.bind(this)
  }

  componentDidMount() {
    if (this.props.currentUser && this.props.bookOfferExperiment.isEmpty())
      this.props.readEndpoint(`user-experiments/${this.props.currentUser.id}`)

    this.store.addChangeListener(this.onUpdate)
    this.subscriptionStore.addChangeListener(this.onSubscribed)
    SessionStore.addChangeListener(this.goToNextPage)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.coupon.errors.length > 0)
      this.logCouponEvent('user_submitted_coupon_unsuccessfully')
    else if (nextProps.coupon.data.coupon && nextProps.coupon.data.coupon.valid)
      this.logCouponEvent('user_submitted_coupon_successfully')
  }

  onUpdate() {
    const storeState = this.store.getState()
    const newState = {processingCoupon: false}
    if (storeState.errors.length) newState.errors = storeState.errors
    else newState.coupon = storeState.data.coupon
    this.setState(newState)
  }

  goToNextPage() {
    if (this.state.sendUserToInterstitials)
      this.props.nextPage()
  }

  onSubscribed() {
    this.props.readEndpoint('user-configs').then(() => {
      const {currentUser, partner} = this.props
      const errors = this.subscriptionStore.getState().errors
      if (errors.length) {
        this.setState({errors, processing: false})
      } else if (this.props.interstitials && (currentUser && currentUser.has_interstitials)) {
        // Need to fetch current user here so that no network call is called unnecessarily when switching from `pending-requests` to `dashboard` - Atanda
        this.setState({sendUserToInterstitials: true}, SessionApi.fetchCurrentUser)
      } else if (this.state.coupon.percent_off === 100) {
        this.props.nextPage(`${accountSettingsUrl}/payment-received/coupon`)
      } else if (partner.first_year_additional_fee > 0) {
        this.props.nextPage(corpMaxDashboardUrl)
      } else {
        const trialEnd = this.subscriptionStore.getState().data.trial_end
        this.props.nextPage(`${accountSettingsUrl}/payment-received/${this.thankYouModalPath(trialEnd)}`)
      }
    })
  }

  thankYouModalPath(trialEnd) {
    if (moment(trialEnd).diff(moment(new Date()), 'days') <= 7) return 'trial-ending'
    return 'trialing'
  }

  componentWillUnmount() {
    this.store.removeChangeListener(this.onUpdate)
    this.subscriptionStore.removeChangeListener(this.onSubscribed)
    SessionStore.removeChangeListener(this.goToNextPage)
  }

  clearCoupon() {
    this.refs.coupon.clearCoupon()
    this.setState({errors: [], coupon: {}})
  }

  applyCoupon() {
    const coupon = (this.refs.coupon.getCouponCode() || '').trim()
    this.setState({errors: coupon ? [] : 'Please enter a valid coupon.', processingCoupon: !!coupon})
    if (coupon) {
      SubscriptionActions.applyCoupon({
        partner: {
          coupon
        }
      })
    }
  }

  onSubmit() {
    const {coupon} = this.state
    if (this.refs.coupon && this.refs.coupon.getCouponCode() && !coupon.valid)
      this.applyCoupon()
    else
      this.createSubscription()
  }

  createSubscription() {
    const partner = this.props.partner
    const coupon = !$.isEmptyObject(partner.coupon) ? partner.coupon : this.state.coupon

    if (coupon.percent_off === 100) {
      this.setState({processing: true})
      SubscriptionActions.createSubscription({subscriptions: {coupon: coupon.id}})
    } else if (this.refs.creditCardForm.validateForm().length === 0 && this.state.errors.length >= 0) {
      this.setState({processing: true})
      sendCardToStripe(this.refs.creditCardForm.formData(), this.stripeResponseHandler)
    }
  }

  stripeResponseHandler(status, response) {
    const couponRef = this.refs.coupon
    const {coupon} = this.props.partner

    if (status === 200) {
      SubscriptionActions.createSubscription({
        subscriptions: {
          stripe_card_token: response.id,
          coupon: couponRef ? couponRef.getCouponCode() : coupon && coupon.id
        }
      })
    } else {
      this.setState({
        errors: response.error.message,
        processing: false
      })
    }
  }

  logCouponEvent(eventName) {
    Logger.log({
      name: eventName,
      payload: {
        partner_id: this.props.partner.id
      }
    })
  }

  render() {
    const partner = this.props.partner
    const hasPartnerDiscount = !$.isEmptyObject(partner.coupon)
    const coupon = hasPartnerDiscount ? partner.coupon : this.state.coupon
    return (
      <GlobalStyleOverrides>
        <div className={classnames('payment-page', this.props.className, {'show-coupon': partner.first_year_additional_fee === 0})}>
          {this.props.messaging.headline && <h1>{this.props.messaging.headline}</h1>}
          {partner.user_messaging ?
            <TextGutterMedium >
              {partner.user_messaging}
            </TextGutterMedium> :
            <Fragment>
              <h3>{this.props.messaging.subtitle}</h3>
              <PaymentListText
                activePlan={this.props.activePlan}
                coupon={coupon}
                hasPartnerDiscount={hasPartnerDiscount}
                partner={partner}
              />
            </Fragment>
          }
          {
            partner.first_year_additional_fee === 0 &&
            <Fragment>
              {
                hasPartnerDiscount ?
                  <CouponApplied activePlan={this.props.activePlan} callout='Offer' coupon={coupon} partner={partner} /> :
                  (
                    <CouponCode
                      activePlan={this.props.activePlan}
                      partner={partner}
                      applyCoupon={this.applyCoupon}
                      clearCoupon={this.clearCoupon}
                      coupon={coupon}
                      processingCoupon={this.state.processingCoupon}
                      ref='coupon'
                    />
                  )
              }
            </Fragment>
          }
          {!(coupon.percent_off === 100) && <CreditCardFormController ref='creditCardForm' />}
          <Forms.Errors errors={this.state.errors} />
          <Buttons.Button className='submit' onClick={this.onSubmit} processing={this.state.processing}>
            {this.props.messaging.buttonText}
          </Buttons.Button>
          <div className='secure-payment'>
            <img className='payment-image' src={SecureImage} alt='Secure Payment, Everplans' />
            <p className='secure-section-text'>
              All payments are handled through a secure payment processing company and all credit card details are stored on their secured
              servers. Everplans does not store your credit card details.
            </p>
          </div>
        </div>
      </GlobalStyleOverrides>
    )
  }
}

PaymentPage.propTypes = {
  activePlan: PropTypes.instanceOf(Map),
  bookOfferExperiment: PropTypes.instanceOf(Map),
  currentUser: PropTypes.shape({
    id: PropTypes.number,
    has_interstitials: PropTypes.bool
  }),
  coupon: PropTypes.shape({
    data: PropTypes.shape({
      coupon: PropTypes.shape({
        valid: PropTypes.bool
      })
    }),
    errors: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array
    ])
  }),
  interstitials: PropTypes.bool,
  loadingPartner: PropTypes.bool,
  messaging: PropTypes.shape({
    buttonText: PropTypes.string,
    headline: PropTypes.string,
    subtitle: PropTypes.string
  }),
  nextPage: PropTypes.func,
  partner: PropTypes.shape({
    sponsored: PropTypes.bool,
    id: PropTypes.integer,
    suppress_trial: PropTypes.bool,
    credit_card_required: PropTypes.bool,
    coupon: PropTypes.shape({
      id: PropTypes.string,
      amount_off: PropTypes.number,
      percent_off: PropTypes.number
    }),
    first_year_additional_fee: PropTypes.number,
    user_messaging: PropTypes.string
  }),
  readEndpoint: PropTypes.func
}

PaymentPage.defaultProps = {
  messaging: {
    headline: 'Just one more step.',
    subtitle: 'Enter your credit card information to start your trial.',
    buttonText: 'Start Your Free Trial'
  },
  nextPage: goToInterstitials,
  partner: {
    coupon: {
      id: null
    }
  }
}

const mapStateToProps = ({api, plans}) => {
  const experiments = cleanResponse(api['user-experiments']).getIn([0, 'experiments'], List())
  const bookOfferExperiment = experiments.find(experiment => experiment.get('experiment') === 'trialing_users_book_offer_emails')
  const activePlan = findResourceByAttributeId({
    resourceList: plans,
    attribute: 'activated',
    id: true
  })

  return {bookOfferExperiment: bookOfferExperiment || Map(), activePlan}
}

export default storeWrapper(
  connect(mapStateToProps, {readEndpoint})(PaymentPage),
  [{actionType: [SubscriptionActions.Types.DID_APPLY_COUPON], name: 'coupon'}]
)
