import classnames from 'classnames'
import {Component} from 'react'
import {fromJS, OrderedMap} from 'immutable'
import PropTypes from 'prop-types'
import uuid from 'uuid'

import TrashCanIcon from '../../../dashboard/src/components/icons/TrashCanIcon'

import './multipleFormFieldWrapper.scss'


export default function createMultipleFormField(FormField) {
  class MultipleFormFieldWrapper extends Component {
    constructor(props) {
      super(props)

      this.state = {fieldIndices: this.initializeFields(props)}

      this.addField = this.addField.bind(this)
      this.removeField = this.removeField.bind(this)
    }

    initializeFields(props) {
      let fieldIndices = OrderedMap()

      if (props.values && !props.values.isEmpty()) {
        // Prefill all fields with default values -- KAY
        props.values.forEach(value => {
          fieldIndices = fieldIndices.set(uuid.v4(), value)
        })

        return fieldIndices
      } else {
        // Set fieldIndices to default to at least one field -- KAY
        return fieldIndices.set(uuid.v4(), null)
      }
    }

    addField() {
      this.setState(prevState => ({
        fieldIndices: prevState.fieldIndices.set(uuid.v4(), null)
      }))
    }

    removeField({key, name}) {
      return (
        () => {
          this.setState(prevState => ({fieldIndices: prevState.fieldIndices.delete(key)}),
            // Keep the form in sync with updates in the form field -- KAY
            this.context.updateDataAndErrors(name, null, null)
          )
        }
      )
    }

    render() {
      const formFields = this.state.fieldIndices.keySeq().map((key, index) => {
        const fieldName = `${this.props.name}-${key}`

        // Passing the `legend` for each `FormField` instance to maintain web accessiblity
        // and to keep the element width to correclty render it's flex child property correcty.
        // To avoid the duplication of the `legend` text, all instances uses the `visuallyHidden` Sass mixin. --KTW
        const data = fromJS({
          id: fieldName,
          legend: this.props.legend,
          name: fieldName,
          value: this.state.fieldIndices.get(key)
        })

        return (
          <div className={classnames('single-form-field flex-container', this.props.className)} key={key}>
            <FormField data={data} />
            {
              index > 0 &&
              (
                <div className='delete-icon' onClick={this.removeField({key, name: fieldName})}>
                  <TrashCanIcon />
                </div>
              )
            }
          </div>
        )
      })
      return (
        <div className='multiple-form-field-wrapper'>
          <div className='form-field-container'>{formFields}</div>
          <div className='add-another-field'><a onClick={this.addField}>Add another</a></div>
        </div>
      )
    }
  }

  MultipleFormFieldWrapper.contextTypes = {updateDataAndErrors: PropTypes.func}

  MultipleFormFieldWrapper.propTypes = {
    name: PropTypes.string,
    legend: PropTypes.string
  }

  return MultipleFormFieldWrapper
}
