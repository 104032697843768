import PropTypes from 'prop-types'
import {useEffect, useState} from 'react'
import {Map} from 'immutable'

import AnnouncementTooltip from '../../../../../shared_components/core/tooltips/AnnouncementTooltip'
import {Button} from '../../blocks/Buttons'
import GearIcon from '../../icons/GearIcon'
import ManageDeputyPermissionsToolTip from './ManageDeputyPermissionsToolTip'
import Logger from '../../../../../lib/NewLogger'

import {corpMaxManageDeputyPermissionsPath} from '../../../../../lib/urlTools'
import {pluralize} from '../../../../../lib/tools'
import {setViewedFlagV2} from '../../../../../lib/userTools'
import {usePrevious} from '../../../../../lib/hooks'
import routerUtils from '../../../../../lib/routerUtils'

import './deputyPermissionsSummary.scss'


const DeputyPermissionsSummary = props => {
  const suppressToolTip = (props.userConfig.get('account-manager') || props.userConfig.get('admin'))

  const [displayTooltip, setDisplayTooltip] = useState(!suppressToolTip)
  const [showFirstVisitTooltip, setShowFirstVisitTooltip] = useState(props.currentUser.getIn(['viewed-flags', 'has_seen_manage_button_tooltip']) || !props.hasDeputyWithDeferredItems)
  const prevHasDeputyWithDeferredItems = usePrevious(props.hasDeputyWithDeferredItems)

  useEffect(() => {
    if (props.hasDeputyWithDeferredItems !== prevHasDeputyWithDeferredItems && !suppressToolTip) {
      setShowFirstVisitTooltip(false)
      setDisplayTooltip(true)
    }
  }, [props.hasDeputyWithDeferredItems, prevHasDeputyWithDeferredItems])

  const checkVisitToNewDeputyScreen = () => {
    /*
      Need these existence checks to fix a Rollbar error that is thrown when either the user's session has timed out
      before this check is made or a user who is not logged in clicks a bookmark taking them to the My Deputies
      page. --Atanda and BLR
    */
    if (props.currentUser) {
      if (showFirstVisitTooltip)
        return !props.currentUser.getIn(['viewed-flags', 'first_visit_to_after_death_screen'])
      else
        return !props.currentUser.getIn(['viewed-flags', 'has_seen_manage_button_tooltip'])
    } else {
      return false
    }
  }

  const logEvent = ({name, context}) => {
    Logger.log({
      name,
      payload: {context}
    })
  }

  const hideTooltip = () => {
    if (showFirstVisitTooltip)
      setDisplayTooltip(false)
  }

  const updateUserFlag = flagName => {
    setViewedFlagV2({
      user: props.currentUser,
      flagToSet: flagName,
      updateResource: props.updateResource
    })
  }

  const dismissTooltip = () => {
    updateUserFlag(showFirstVisitTooltip ? 'first_visit_to_after_death_screen' : 'has_seen_manage_button_tooltip')
  }

  const managePermissionLink = () => `${corpMaxManageDeputyPermissionsPath(props.deputy.get('id'))}?context=my_deputies`

  const goToManagePermissions = () => {
    logEvent({
      name: 'clicked_deputy_permissions',
      context: 'deputy_permissions_all_items'
    })

    routerUtils.push(managePermissionLink())
  }

  const goToUnlockPermissions = () => {
    logEvent({
      name: 'clicked_deputy_permissions',
      context: 'deputy_permissions_unlocker'
    })

    routerUtils.push(`cm/deputies/${props.deputy.get('id')}/manage-unlock-permissions`)
  }

  const permissionsSummaryItem = (count, text) => <div><strong>{count}</strong>{text}</div>

  const sadTooltipText = showFirstVisitTooltip ?
    "Tell us which items you want to share only after you're gone." :
    "Manage deputy's permission to unlock here."

  const immediatePermissions = () => {
    const numberOfReadPermissions = props.deputy.getIn(['permissions', 'read']).size

    return permissionsSummaryItem(numberOfReadPermissions, ` ${pluralize('item', numberOfReadPermissions)} shared now`)
  }

  const deferredPermissions = () => {
    const numberOfDeferredReadPermissions = props.deputy.getIn(['permissions', 'deferred']).size

    return permissionsSummaryItem(numberOfDeferredReadPermissions, ` ${pluralize('item', numberOfDeferredReadPermissions)} locked until after death`)
  }

  return (
    <div className='deputy-permissions-summary flex-child flex-container deputy-item'>
      <section className='flex-child manage-permissions'>
        <div className='section-header-text'>Permissions</div>
        <div className='current-permissions thin-text'>
          <div className='sharing-summaries'>
            {immediatePermissions()}
            {props.hasDeputyWithDeferredItems && deferredPermissions()}
          </div>
          {
            props.hasDeputyWithDeferredItems &&
              `${props.deputy.get('death-reporter') ? 'Allowed' : 'Not allowed'} to unlock`
          }
        </div>
      </section>
      <section className='action-bar flex-child flex-container'>
        {
          props.hasDeputyWithDeferredItems ?
            (
              <ManageDeputyPermissionsToolTip
                goToManagePermissions={goToManagePermissions}
                goToUnlockPermissions={goToUnlockPermissions}
              />
            ) :
            <div className='flex-container view-manage-permissions' onClick={goToManagePermissions}>
              <GearIcon />
                Manage
            </div>
        }
        <AnnouncementTooltip hideTooltip={hideTooltip} showTooltip={checkVisitToNewDeputyScreen() && displayTooltip}>
          <p>
            {
              showFirstVisitTooltip &&
                <strong>New! </strong>
            }
            {sadTooltipText}
          </p>
          <Button onClick={dismissTooltip}>Got it</Button>
        </AnnouncementTooltip>
      </section>
    </div>
  )
}


DeputyPermissionsSummary.propTypes = {
  currentUser: PropTypes.instanceOf(Map),
  deputy: PropTypes.instanceOf(Map),
  hasDeputyWithDeferredItems: PropTypes.bool,
  updateResource: PropTypes.func,
  userConfig: PropTypes.instanceOf(Map)
}

export default DeputyPermissionsSummary
