import {useEffect} from 'react'
import PropTypes from 'prop-types'
import {Map, fromJS} from 'immutable'
import {useState} from 'react'
import {Controller, useForm} from 'react-hook-form'

import AnimatedProgressBar from '../../../../shared_components/AnimatedProgressBar'
import SelectButtonGroup from '../../../../shared_components/SelectButtonGroup'
import {Button} from '../blocks/Buttons'
import {hasEmptyAssessmentFormValues} from '../../lib/onboardingAssessmentTools'
import Logger from '../../../../lib/NewLogger'
import {numberToWord} from '../../../../lib/tools'

import './assessmentScreen.scss'

const AssessmentScreen = props => {
  const {
    completedQuestionsPercentage,
    screen,
    nextScreen,
    logMixpanelEvent
  } = props
  const {control, handleSubmit} = useForm()
  const [showError, setShowError] = useState(false)
  const screenName = screen.get('name')

  const logEvent = ({name, payload = {}}) => Logger.log({name, payload})

  useEffect(() => {
      logEvent({name: 'viewed_onboarding_assessment_question', payload: {assessment_type: screenName}})
  }, [screenName])

  const logEvents = () => {
    logMixpanelEvent({name: 'view_assessment', payload: {step: screenName}})
  }

  const hasNoResponse = screenAnswer => screen.get('options-type') === 'checkbox' && !screenAnswer.getIn([0, 'multi-select'])

  const onClickContinue = newData => {
    const formData = fromJS(newData)
    const eventPayload = {assessment_type: screenName}

    if (hasEmptyAssessmentFormValues(formData)) {
      screen.get('typename') === 'ScoreAssessmentScreen' ?
        setShowError(true) :
        nextScreen(screen.get('options'))
    } else {
      setShowError(false)
      nextScreen(formData)

      // This applies to checkboxes where user select "I don't have" or "None of the above"
      if (hasNoResponse(formData.get(screenName)))
        logEvent({name: 'continued_without_answer_on_assessment', payload: eventPayload})
      else
        logEvent({name: 'continued_with_answer_on_assessment', payload: eventPayload})
    }

    logEvents()
  }

  return (
    <div className='assessment-screen'>
      <AnimatedProgressBar
        completed={completedQuestionsPercentage}
        show={screen.has('options')}
      />
      <div className='body'>
        {screen.get('body').map(text => (
          <div
            className='body-text'
            key={text}>
            {text}
          </div>
        ))}
        {screen.get('options') && (
          <form key={screen.get('id')} name={screenName}>
            <Controller
              name={screenName}
              control={control}
              render={({field}) => (
                <SelectButtonGroup
                  field={field}
                  isSingleSelect={screen.get('options-type') === 'radio'}
                  options={screen.get('options').toJS()}
                  prompt={screen.get('maximum-selections') ? `Select up to ${numberToWord(screen.get('maximum-selections'))}` : ''}
                  selectLimit={screen.get('maximum-selections') || screen.get('options').size}
                  showError={showError}
                />
              )}
            />
          </form>
        )}
      </div>
      <Button className='continue' onClick={handleSubmit(onClickContinue)}>
        Continue
      </Button>
    </div>
  )
}

AssessmentScreen.propTypes = {
  completedQuestionsPercentage: PropTypes.number,
  logMixpanelEvent: PropTypes.func.isRequired,
  screen: PropTypes.instanceOf(Map),
  nextScreen: PropTypes.func,
  resetAssessment: PropTypes.func
}

export default AssessmentScreen
