import classnames from 'classnames'
import PropTypes from 'prop-types'

import {Box, Modal, Typography, styled} from '@mui/material'

import breakpoints from '../../../../dashboard/styles/abstracts/breakpoints.module.scss'
import CloseIcon from '../../../core/svg_icons/icons/CloseIcon'
import colors from '../../../../dashboard/styles/abstracts/colors.module.scss'

/* Override any of the following styles using the MuiModal-root class */

const StyledBox = styled(Box)(
  () => `
    align-items: center;
    background: ${colors.boxBackground};
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    font-feature-settings: 'liga' off, 'clig' off;
    margin: 134px auto 0;
    max-width: 480px;
    outline: none;
    padding: 32px;
    width: 90%;
    @media (min-width: ${breakpoints.mobileMd}) {
      padding: 40px;
    }
  `
)

const StyledH2Typography = styled(Typography)(
  () => `
    &.heading {
      font-size: 1.3125rem;
      font-weight: 600;
      line-height: 109.524%;
      margin: 12px 0 16px;
      text-align: center;
    }
  `
)

const StyledBasicModal = props => {
  const {
    children,
    className,
    headingText,
    onClose,
    open
  } = props

  return (
    <Modal
      className={classnames('basic-modal', className)}
      data-testid='basic-modal'
      onClose={reason => (reason === 'backdropClick' ? null : onClose)}
      open={open}
      sx={{
        overflowX: 'hidden',
        zIndex: '1302'
      }}>
      <StyledBox>
        <CloseIcon
          data-testid='close-icon'
          height='12'
          onClick={onClose}
          style={{alignSelf: 'flex-end', cursor: 'pointer'}}
          width='12'
        />
        <StyledH2Typography className='heading' variant='h2'>
          {headingText}
        </StyledH2Typography>
        {children}
      </StyledBox>
    </Modal>
  )
}

StyledBasicModal.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  headingText: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
}

export default StyledBasicModal
